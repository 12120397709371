<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <h5 class="font-weight-bold text-h5 text-typo mb-0">xxx бүртгэл</h5>
      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>
      <v-row justify="end" class="mr-2">
        <v-toolbar flat color="transparent">
          <v-row justify="space-between">
            <v-row justify="end">
              <v-btn
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                elevation="0"
                :ripple="false"
                @click="_newDialog(null)"
                :title="$attrs.name + ' нэмэx'"
              >
                <v-icon class="darken-2" text>Сургууль+</v-icon>
              </v-btn>
            </v-row>
          </v-row>
        </v-toolbar>
      </v-row>
      <v-card class="border-radius-xl card-shadow">
        <v-data-table
          :headers="headers"
          v-if="filteredSchools && filteredSchools.length > 0"
          :items="filteredSchools"
          :items-per-page="500"
        >
          <template slot="item" slot-scope="props">
            <tr @click.stop="_detail(props.item, props.item.index)">
              <td @click.stop="_print(props.item)">
                {{ props.item.index }}
              </td>
              <td>
                <div class="d-flex flex-row">
                  <v-progress-circular
                    v-if="props.item.esisLoading"
                    size="40"
                    color="red"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-card v-else class="text-center" height="300">
          <v-row justify="center">
            <v-img
              class="mx-auto mt-16"
              max-height="100"
              max-width="100"
              src="/assets/img/notfound.png"
            ></v-img>
          </v-row>
          <h5 class="py-10 text-danger">Мэдээлэл оруулаагүй байна</h5>
        </v-card>
      </v-card>

      <div class="py-3" />

      <v-dialog v-model="newDialog" max-width="800px">
        <v-card class="py-4">
          <v-card-title class="headline"> Шинэ сургууль нэмэxxxx </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    autofocus
                    v-model.trim="editedItem.name"
                    label="Нэр"
                    :error="error"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    autofocus
                    v-model.trim="editedItem.city"
                    label="City Нэр"
                    :error="error"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model.trim="editedItem.email"
                    label="Систем xариуцаx xүний Gmail имэйл"
                    :error="error"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.trim="editedItem.schoolIndex"
                    label="schoolIndex"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem.directorLastName"
                    label="Захирлын овог"
                    :error="error"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem.directorFirstName"
                    label="Захирлын нэр"
                    :error="error"
                  />
                </v-col>

                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem.directorPhone"
                    label="Утас"
                    :error="error"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem._esisUserName"
                    label="User Name"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem._esisPword"
                    label="Pword"
                    :error="error"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text>
            <v-container>
              <span class="red--text">{{ this.messageNotification }}</span>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="_closeEdit"> Цуцлаx </v-btn>
            <v-btn
              class="btn-primary bg-gradient-primary text-capitalize"
              @click="saveItem"
            >
              Xадгалаx
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="detailDialog" max-width="500px">
        <v-card class="py-4">
          <v-card-title class="headline">
            {{ editedItem.name }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p>{{ editedItem.name }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <p>{{ editedItem.shortName }}</p>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <p>{{ editedItem.code }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p class="text-wrap text--primary">Info</p>
                  {{ editedItem._esis_schoolInfo }}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    collectionPath:"dfsdfs",
    selectedCity: null,
    loading: false,
    error: false,
    detailDialog: false,
    newDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,

    items: [],
    messageNotification: null,
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    cities() {
      var list = this.items.map((ss) => ss.city);
      const uniqueList = list
        .filter((item, index) => {
          return list.indexOf(item) === index;
        })
        .map((item, index) => {
          return {
            index: index + 1,
            value: item,
            displayText: `${index + 1}. ${item}`,
          };
        });
      return uniqueList;
    },
    filteredSchools() {
      if (this.selectedCity) {
        return this.items
          .filter((ss) => ss.city == this.selectedCity)
          .map((item, index) => {
            item.index = index + 1;
            return item;
          });
      } else {
        return this.items;
      }
    },

    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "esis",
          align: "start",
          sortable: true,
          width: "20%",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "name",
          width: "20%",
        },
        {
          text: "City Нэр",
          align: "start",
          sortable: true,
          value: "city",
          width: "20%",
        },
      ];
    },
  },
  created() {
    this.headers.concat([
      {
        text: "Имэйл",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Захирал",
        align: "start",
        sortable: true,
        value: "directorName",
      },
    ]);
    this._read();
  },

  methods: {
    printLog() {
      var counter = 0;
      this.logLists = [];
      fb.db
        .collection("schoolGradeErrorLogs2")
        .where("failReason", "==", "missing plans")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let dataa = doc.data();
            console.log(dataa);
            dataa.school.ref.get().then((school) => {
              fb.db
                .doc(dataa.lessonRef)
                .get()
                .then((doc) => {
                  var lesson = doc.data();
                  var ddd = {
                    schoolId: school.id,
                    schoolName: school.data().name,
                    schoolLegalEntityId:
                      school.data()._esis_schoolInfo.legalEntityId,
                    COURSE_ID: lesson.courseInfo.COURSE_ID,
                    COURSE_NAME: lesson.courseInfo.COURSE_NAME,
                    PROGRAM_OF_STUDY_ID: lesson.courseInfo.PROGRAM_OF_STUDY_ID,
                  };
                  var found = this.logLists.find(
                    (ss) =>
                      ss.schoolId == school.id &&
                      ss.COURSE_ID == lesson.courseInfo.COURSE_ID
                  );
                  if (!found) {
                    counter++;
                    ddd.counter = counter;
                    console.log(ddd.counter, ddd);
                    this.logLists.push(ddd);
                  }
                });
            });
          });
        });
    },

    _getPath() {
      return "schools";
    },

    _newDialog(item) {
      this.editedItem = {};
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },

    _detail(item) {
      this.editedItem = item;
      this.detailDialog = true;
    },

    cancel() {
      this._closeEdit();
    },
    _deleteItem(item) {
      this.editedItem = item;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.editedIndex = -1;
    },
    async saveItem() {
      //var tmp = Object.assign({}, this.editedItem);
      if (this.editedItem.name && this.editedItem.name.trim() !== "") {
        this.editedItem.createdAt = new Date();
        if (!this.editedItem.directorName) this.editedItem.directorName = null;
        if (!this.editedItem.managerName) this.editedItem.managerName = null;

        if (this.editedIndex === -1) {
          this.editedItem.realschool = true;
          this.editedItem.deleted = false;
          this.editedItem.email = this.editedItem.email
            ? this.editedItem.email.toLowerCase()
            : null;

          if (new Date().getMonth() + 1 < 7) {
            this.editedItem.currentYear = new Date().getFullYear() - 1;
          } else {
            this.editedItem.currentYear = new Date().getFullYear();
          }

          this.editedItem.currentYearIndex = 0;

          await fb.db
            .collection(this._getPath())
            .doc()
            .set(this.editedItem)
            .then(() => {
              this._closeEdit();
            });
        } else {
          this.editedItem.email = this.editedItem.email
            ? this.editedItem.email.trim().toLowerCase()
            : null;
          this.editedItem.ref
            .update(this.editedItem)
            .then(() => {})
            .then(() => {
              this._closeEdit();
            });
        }
      } else {
        this.error = true;
        this.messageNotification =
          "Доод тал нь <<нэр, email>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },

    _read() {
      fb.db
        .collection("schools")
        .where("deleted", "==", false)
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.items = [];
          var index = 0;
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.directorName = "";
            item.directorName = item.directorLastName
              ? item.directorLastName + ", " + item.directorFirstName
              : item.directorFirstName;
            item.managerName = item.managerLastName
              ? item.managerLastName + ", " + item.managerFirstName
              : item.managerFirstName;

            item.index = ++index;
            item.loading = false;
            this.items.push(item);
          });
        });
    },

    _getFormatedDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
